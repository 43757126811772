import { DashboardSchadeafhandelingInfo } from '@/support/Info';
import { Component } from 'vue-property-decorator';
import { AxiosError, AxiosResponse } from 'axios';
import { Rpc } from '@/models/Rpc';
import ErrorHandler from '@/support/ErrorHandler';
import { getStatusLabel } from '@/support/ReportStatus';
import { getKpiClass } from '@/support/Dashboard';
import AbstractMoreInfoDialog from '@/components/dialog/more-info-dialog/AbstractMoreInfoDialog';
import { MoreInfo } from '@/components/dialog/more-info-dialog/MoreInfoDialog';

@Component<Dashboard>({})
export default class Dashboard extends AbstractMoreInfoDialog {
  public $pageTitle = 'Dashboard';

  protected isLoading = true;

  protected moreInfo: MoreInfo[] = DashboardSchadeafhandelingInfo;

  protected statistics: any;

  protected mounted() {
    this.initBreadcrumb();
    this.initialize();
  }

  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Dashboard' },
        ],
      });
  }

  protected async initialize() {
    await this.fetchStatistics();

    this.isLoading = false;
  }

  protected getStatusLabel(status: string) {
    if (! status) { return ''; }
    return getStatusLabel(status);
  }

  protected async fetchStatistics() {
    const payload = {
      signature: 'management-reports:damage-handler-dashboard',
      body: {
      },
    };

    await new Rpc()
      .rpcPost(payload, false)
      .then((response: AxiosResponse) => {
        this.statistics = response.data;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.isLoading = false;
      });
  }

  protected getKpiClass(amount: number, warningAt = 5, alertAt = 10, inverted = false) {
    return getKpiClass(amount, warningAt, alertAt, inverted);
  }
}
