var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [_vm._v("Dashboard")])
          ]),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel" },
              [
                _c(
                  "v-layout",
                  { attrs: { wrap: "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c(
                          "v-layout",
                          { attrs: { wrap: "" } },
                          [
                            _c(
                              "v-flex",
                              { attrs: { xs12: "" } },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      wrap: "",
                                      "justify-space-between": ""
                                    }
                                  },
                                  [
                                    _c("v-flex", [
                                      _c(
                                        "h2",
                                        { staticClass: "elementSubTitle" },
                                        [_vm._v("Huidige werkvoorraad")]
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "elementPanel elementPanel--spaced"
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        attrs: { xs12: "", "text-center": "" }
                                      },
                                      [
                                        _vm.isLoading
                                          ? _c("LoaderCard", {
                                              attrs: {
                                                flat: "",
                                                type: "spinner--center"
                                              }
                                            })
                                          : _vm._e(),
                                        !_vm.isLoading
                                          ? [
                                              _c(
                                                "v-layout",
                                                { attrs: { wrap: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs12: "" } },
                                                    [
                                                      _c(
                                                        "v-layout",
                                                        {
                                                          attrs: {
                                                            wrap: "",
                                                            "justify-space-between":
                                                              ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "info-icon",
                                                              attrs: {
                                                                shrink: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    bottom: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                {
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.openReadMoreDialog(
                                                                                        "rapport_aantallen"
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  " info_outline "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    235925534
                                                                  )
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "klik voor meer info"
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs6: "",
                                                        md4: ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "router-link",
                                                        {
                                                          attrs: {
                                                            to:
                                                              "/reports?status=zienswijze_approved"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "StatisticsNumberWidget",
                                                            {
                                                              attrs: {
                                                                item: {
                                                                  class: [
                                                                    _vm.getKpiClass(
                                                                      _vm
                                                                        .statistics
                                                                        .status_totals[
                                                                        "zienswijze_approved"
                                                                      ]
                                                                    )
                                                                  ],
                                                                  amount:
                                                                    _vm
                                                                      .statistics
                                                                      .status_totals
                                                                      .zienswijze_approved,
                                                                  description: _vm.getStatusLabel(
                                                                    "zienswijze_approved"
                                                                  )
                                                                }
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs6: "",
                                                        md4: ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "router-link",
                                                        {
                                                          attrs: {
                                                            to:
                                                              "/reports?status=zienswijze_corrected"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "StatisticsNumberWidget",
                                                            {
                                                              attrs: {
                                                                item: {
                                                                  class: [
                                                                    _vm.getKpiClass(
                                                                      _vm
                                                                        .statistics
                                                                        .status_totals[
                                                                        "zienswijze_corrected"
                                                                      ]
                                                                    )
                                                                  ],
                                                                  amount:
                                                                    _vm
                                                                      .statistics
                                                                      .status_totals
                                                                      .zienswijze_corrected,
                                                                  description: _vm.getStatusLabel(
                                                                    "zienswijze_corrected"
                                                                  )
                                                                }
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs6: "",
                                                        md4: ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "router-link",
                                                        {
                                                          attrs: {
                                                            to:
                                                              "/reports?status=zienswijze_werkvoorbereiding_rejected"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "StatisticsNumberWidget",
                                                            {
                                                              attrs: {
                                                                item: {
                                                                  class: [
                                                                    _vm.getKpiClass(
                                                                      _vm
                                                                        .statistics
                                                                        .status_totals[
                                                                        "zienswijze_werkvoorbereiding_rejected"
                                                                      ]
                                                                    )
                                                                  ],
                                                                  amount:
                                                                    _vm
                                                                      .statistics
                                                                      .status_totals
                                                                      .zienswijze_werkvoorbereiding_rejected,
                                                                  description: _vm.getStatusLabel(
                                                                    "zienswijze_werkvoorbereiding_rejected"
                                                                  )
                                                                }
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm.isReadingMoreInfo
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isReadingMoreInfo,
                callback: function($$v) {
                  _vm.isReadingMoreInfo = $$v
                },
                expression: "isReadingMoreInfo"
              }
            },
            [
              _c("MoreInfoDialog", {
                attrs: {
                  title: _vm.activeMoreInfo.title,
                  description: _vm.activeMoreInfo.description
                },
                model: {
                  value: _vm.isReadingMoreInfo,
                  callback: function($$v) {
                    _vm.isReadingMoreInfo = $$v
                  },
                  expression: "isReadingMoreInfo"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }